<template>
  <!-- 仓库详情 -->
  <el-dialog
  v-bind="$attrs"
  title="仓库详情"
  width="920"
  class="stockInfo"
  :close-on-click-modal="false"
  destroy-on-close
  align-center
  @close="close"
  >
    <div class="stock-content">
        <div class="stock-content-item dp_f">
          <div class="stock-content-left single">
            <div class="title">仓库名称</div>
            <div class="stock-input">
              {{stockInfo.stockName}}
            </div>
          </div>
          <div class="stock-content-right single">
            <div class="title">地址</div>
            <div class="stock-input">
              {{stockInfo.stockAddress}}
            </div>
          </div>
        </div>
        <div class="stock-content-item-one">
          <div class="single">
            <div class="title">备注</div>
            <div class="stock-input">
                {{stockInfo.remark}}
            </div>
          </div>
        </div>
      </div>
   <template #footer >
        <el-button class="btn1" type="primary" @click='edit'>编辑</el-button>
      </template>
  </el-dialog>
</template>

<script setup>
import {ref, toRefs, reactive, getCurrentInstance} from 'vue'
const { proxy } = getCurrentInstance()
const props = defineProps({
  stockInfo: {
    type: Object,
    default: () => {}
  }
})
const {stockInfo} = toRefs(props)
const emit = defineEmits(['close','change'])
const openDetail = () => {
}
const edit = () => {
  emit('change')
}
const close = () => {
  emit('close')
}
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name:"StockDetail",
})
</script>

<style lang="scss" scoped>
.stockInfo{
  .stock-content-item{
    .stock-input{
    @include textOverflow(360px);
    width: 360px;
  }
  }
  .stock-content-item-one{
     .stock-input{
    @include textOverflow(100%);
  }
  }
}
</style>