<template>
  <!--  -->
    <el-dialog
      v-bind="$attrs"
      :title="stockInfo.stockId?'编辑仓库':'添加仓库'"
      width="920"
      class="stockInfo"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @close="close"
      >
      <div class="stock-content">
        <div class="stock-content-item dp_f">
          <div class="stock-content-left single">
            <div class="title"><span class="red">*</span>仓库名称</div>
            <div class="stock-input">
              <el-input v-elInput class="w360" maxlength="20" v-model="stockInfo.stockName" placeholder="请输入仓库名称"></el-input>
            </div>
          </div>
          <div class="stock-content-right single">
            <div class="title">地址</div>
            <div class="stock-input">
              <el-input v-elInput class="w360" maxlength="100" v-model="stockInfo.stockAddress" placeholder="请输入地址"></el-input>
            </div>
          </div>
        </div>
        <div class="stock-content-item-one">
          <div class="single">
            <div class="title">备注</div>
            <div class="stock-input">
                <el-input
                v-elInput
                  type="textarea"
                  placeholder="输入信息"
                  class="textareaInput"
                  resize="none"
                  maxlength="100"
                  v-model="stockInfo.remark"
                />
            </div>
          </div>
        </div>
      </div>
     <template #footer >
        <el-button class="btn1 zh-btn" @click='close'>取消</el-button>
        <el-button class="btn1" type="primary" @click='saveStock'>保存</el-button>
      </template>
  </el-dialog>
</template>

<script setup>
import {ref, toRefs, reactive, getCurrentInstance, onMounted} from 'vue'
import { stockInfoAdd,stockInfoEdit } from '@/utils/api/procurement/stock'
const { proxy } = getCurrentInstance()
const props = defineProps({
  stockInfo: {
    type: Object,
    default: () => {}
  }
})
const { stockInfo } = toRefs(props)
const emit = defineEmits(['close','change'])
const close = () => {
  emit('close')
}

const saveStock = () => {
  if(!stockInfo.value.stockName){
    proxy.$message.error('请输入仓库名称')
    return
  }
  let api = stockInfo.value.stockId?stockInfoEdit:stockInfoAdd
  let params = {
    stockName:stockInfo.value.stockName,
    stockAddress:stockInfo.value.stockAddress,
    remark:stockInfo.value.remark,
    stockId:stockInfo.value.stockId
  }
  api(params).then((res) => {
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    if(stockInfo.value.stockId){
      proxy.$message.success('编辑成功')
      emit('change',stockInfo.value)
      emit('close')
    }else{
      proxy.$message.success('新增成功')
      emit('close')
    }
  })
}

</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name:"StockChange",
})
</script>
<style lang="scss" scoped>
@import url('./stock.scss');
</style>