<template>
  <!-- 仓库管理 -->
  <div class="zh-main-app">
    <div class="zh-main-content stockList">
      <div class="queryBoxOne">
        <div class="btnBox">
          <el-button type="primary" @click="addStock">
            <span class="text">添加仓库</span>
          </el-button>
        </div>
        <div class="queryItem">
          <el-input
            class="w240"
            placeholder="请输入仓库名称"
            @keydown.enter="getList"
            v-model="formData.query"
            clearable
            v-elInput
          >
          </el-input>
          <div class="queryBtn">
            <el-button type="primary" @click="getList"
              ><el-icon><Search /></el-icon
            ></el-button>
          </div>
        </div>
      </div>
      <div class="tabList">
        <!-- 列表 -->
        <el-table
          :data="tableData"
          style="width: 100%"
          height="100%"
          class="zh-no-table-border"
          :row-class-name="tableRowClassName"
          :cell-style="{ border: 'none' }"
          :header-row-style="{ border: 'none' }"
          @cell-click="openInfoDia"
        >
          <el-table-column label="序号" width="100" align="center">
            <template #default="scope">
              <div class="table_text">{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column
            width="300"
            prop="stockName"
            label="仓库名称"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            label="仓库地址"
            width="300"
            align="center"
            show-overflow-tooltip
            prop="stockAddress"
            class-name="table_text"
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            width="300"
            align="center"
            class-name="table_text"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="创建人"
            width="200"
            align="center"
            class-name="table_text"
            show-overflow-tooltip
            prop="createUserName"
          >
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="200"
            align="center"
            class-name="table_text"
            show-overflow-tooltip
          >
            <template #default="scope">
              <div class="table_text">
                {{ $filters.timeFormat(scope.row.createTime, 1) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template #default="scope">
              <el-switch
                v-model="scope.row.status"
                :before-change="changeStatus.bind(this, scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <StockDetail
      v-model="showStockDetail"
      :stockInfo="stockInfo"
      @change="editStock"
      @close="closeDetail"
    ></StockDetail>
    <Stock
      v-model="addEditFlag"
      :stockInfo="addInfo"
      @close="closeAddEdit"
      @change="saveStock"
    ></Stock>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import { tableRowClassName } from "@/utils/tool.js";
import StockDetail from "./components/stockDetail.vue";
import { ElMessageBox } from "element-plus";
import { stockInfoList, stockInfoUpdate } from "@/utils/api/procurement/stock";
import Stock from "./components/stock.vue";
import { cloneDeep } from "lodash";
const { proxy } = getCurrentInstance();
const formData = reactive({
  query: "",
});

const tableData = ref([]);
const stockInfo = ref({});
const showStockDetail = ref(false);
//新增编辑标识
const addEditFlag = ref(false);
const addInfo = reactive({
  stockName: "",
  stockAddress: "",
  remark: "",
});
const getList = () => {
  stockInfoList({
    query: formData.query,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    tableData.value = res?.data?.list || [];
  });
};
const openInfoDia = (row, column, cell, even) => {
  if (column.property) {
    stockInfo.value = cloneDeep(row);
    showStockDetail.value = true;
  }
};

//重置stockInfo
const resetStockInfo = () => {
  stockInfo.value = {};
};

//重置addInfo
const resetAddInfo = () => {
  addInfo.stockName = "";
  addInfo.stockAddress = "";
  addInfo.remark = "";
  addInfo.stockId = "";
};

//关闭详情弹窗
const closeDetail = () => {
  showStockDetail.value = false;
  resetStockInfo();
};

//关闭新增编辑弹窗
const closeAddEdit = () => {
  addEditFlag.value = false;
  getList();
  resetAddInfo();
};

//编辑弹窗成功刷新详情
const saveStock = (e) => {
  stockInfo.value.stockName = e.stockName;
  stockInfo.value.stockAddress = e.stockAddress;
  stockInfo.value.remark = e.remark;
  stockInfo.value.stockId = e.stockId;
  closeAddEdit();
};

//新增仓库
const addStock = () => {
  resetAddInfo();
  addEditFlag.value = true;
};

//编辑仓库
const editStock = () => {
  addInfo.stockName = stockInfo.value.stockName;
  addInfo.stockAddress = stockInfo.value.stockAddress;
  addInfo.remark = stockInfo.value.remark;
  addInfo.stockId = stockInfo.value.stockId;
  addEditFlag.value = true;
  showStockDetail.value = false;
};

//开启关闭仓库启用状态
const changeStatus = (row) => {
  let text = row.status ? "确认禁用仓库？" : "确认启用仓库？";
  return ElMessageBox.confirm(text, "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    return new Promise((resolve, reject) => {
      stockInfoUpdate({
        stockId: row.stockId,
        status: row.status ? false : true,
      })
        .then((res) => {
          if (res.code != 0) {
            proxy.$message.error(res.msg);
            return reject(false);
          }
          proxy.$message.success("操作成功");
          return resolve(true);
        })
        .catch(() => {
          return reject(false);
        });
    });
  });
};
onMounted(() => {
  getList();
});
</script>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "StockList",
});
</script>

<style lang="scss" scoped>
@import "./index.scss";
.stockList {
  padding-top: 32px !important;
}
</style>
